export const AUTHORS_TABLE_FIELD = [
  {
    name: '__slot:thumbnail',
    width: '30px',
    height: '45px',
    dataClass: 'text-center',
  },
  {
    name: 'name',
    title: 'Name',
    width: '75%',
  },
  {
    name: '__slot:actions',
    title: 'Action',
    width: '100px',
    dataClass: 'text-right',
  },
];

export const GENRES_TABLE_FIELD = [
  {
    name: '__slot:thumbnail',
    width: '30px',
    height: '45px',
    dataClass: 'text-center',
  },
  {
    name: 'name',
    title: 'Name',
    width: '50%',
  },
  {
    name: '__slot:genreGroup',
    title: 'Group',
    width: '30%',
  },
  {
    name: '__slot:actions',
    title: 'Action',
    width: '100px',
    dataClass: 'text-right',
  },
];

export const USERS_TABLE_FIELD = [
  {
    name: '__slot:user-checkbox',
    title: '',
    width: '20px',
  },
  {
    name: '__slot:profile',
    width: '30px',
    height: '45px',
    dataClass: 'text-center',
  },
  {
    name: 'username',
    title: 'Username',
    width: '30%',
  },
  {
    name: '__slot:subscription',
    title: 'Subscribed Level',
    width: '20%',
  },
  {
    name: '__slot:verification',
    title: 'Verification',
    width: '20%',
  },
  {
    name: '__slot:status',
    title: 'Status',
    width: '10%',
  },
  {
    name: '__slot:createdAt',
    title: 'Created At',
    width: '30%',
    dataClass: 'text-right',
  },
];

export const ADMIN_USERS_TABLE_FIELD = [
  {
    name: 'name',
    title: 'Name',
    width: '15%',
  },
  {
    name: 'email',
    title: 'Email',
    width: '20%',
  },
  {
    name: '__slot:roles',
    title: 'Roles',
    width: '30%',
  },
  {
    name: '__slot:createdAt',
    title: 'Created At',
    width: '20%',
    dataClass: 'text-right',
  },
  {
    name: '__slot:actions',
    title: 'Actions',
    width: '15%',
  },
];

export const ROLE_TABLE_FIELDS = [
  {
    name: 'name',
    title: 'Name',
    width: '30%',
  },
  {
    name: 'description',
    title: 'Description',
    width: '20%',
  },
  {
    name: '__slot:createdAt',
    title: 'Created At',
    width: '20%',
    dataClass: 'text-right',
  },
  {
    name: '__slot:actions',
    title: 'Actions',
    width: '15%',
  },
];

export const MANGA_TABLE_FIELDS = [
  {
    name: '__slot:cover',
    width: '40px',
    height: '40px',
    dataClass: 'text-center',
  },
  {
    name: 'title',
    title: 'Title',
  },
  {
    name: '__slot:status',
    title: 'Status',
    width: '10%',
  },
  {
    name: '__slot:disabled',
    title: 'Disabled',
    width: '10%',
  },
  {
    name: '__slot:actions',
    title: 'Actions',
    width: '15%',
  },
];

export const MANGA_SCRAPER_TABLE_FIELDS = [
  {
    name: '__slot:scraper',
    title: 'Scraper',
  },
  {
    name: '__slot:totalChaptersScraped',
    title: 'Total chapters',
  },
  {
    name: '__slot:lastScrapedAt',
    title: 'Last scraped at',
  },
  {
    name: '__slot:actions',
    title: 'Actions',
    width: '25%',
  },
];

export const MANGA_SCRAPERS_TABLE_FIELDS = MANGA_SCRAPER_TABLE_FIELDS;

export const SCRAPING_TASKS_TABLE_FIELDS = [
  {
    name: '__slot:status',
    title: 'Status',
  },
  {
    name: 'trigger',
    title: 'Trigger',
  },
  {
    name: 'type',
    title: 'Type',
  },
  {
    name: 'chaptersScraped',
    title: 'Chapters scraped',
  },
  {
    name: 'chaptersFound',
    title: 'Chapters found',
  },
  {
    name: '__slot:createdAt',
    title: 'Created At',
  },
  {
    name: '__slot:updatedAt',
    title: 'Last updated at',
  },
  {
    name: '__slot:actions',
    title: 'Actions',
    width: '15%',
  },
];
