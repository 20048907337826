import store from '../store';
import moment from 'moment';

export const mangaThumbFilter = mangaId => {
  if (mangaId && mangaId.length) {
    return `${store.getters.mangaURL}/${mangaId}.png`;
  }
  return `${store.getters.mangaURL}/default.png`;
};

export const genrePicFilter = imageId => {
  if (imageId) {
    return `${store.getters.genreURL}/${imageId}.png`;
  }
  return `${store.getters.genreURL}/default.png`;
};

export const profilePicFilter = imageId => {
  if (imageId && imageId.length) {
    return `${store.getters.profileURL}/${imageId}`;
  }
  return `${store.getters.profileURL}/default.png`;
};

export const authorPicFilter = authorId => {
  if (authorId && authorId.length) {
    return `${store.getters.peopleURL}/${authorId}.png`;
  }
  return `${store.getters.profileURL}/default.png`;
};

export const validDateFormat = dateVal => {
  if (dateVal) {
    // BACKLOG: Change it to humanize form later
    return moment(new Date(dateVal)).format('DD-MM-YYYY HH:mm:ss');
  }
  return '';
};

export const momentDaysAgo = dateVal => {
  return moment(new Date(dateVal)).fromNow();
};

export const momentDaysAgoFromSeconds = seconds => {
  const dateVal = new Date();
  dateVal.setSeconds(dateVal.getSeconds() - seconds);
  return moment(dateVal).fromNow();
};

export const scrapingSiteOriginURL = mangaSiteFormatUrl => {
  const urlObject = new URL(mangaSiteFormatUrl);
  return urlObject.origin;
};
