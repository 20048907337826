<template>
  <div>
    <div class="row align--center">
      <div class="flex xs4">
        <va-button @click="showModal = !showModal" :disabled="disabled"
          >Notify users</va-button
        >
      </div>
    </div>
    <va-modal
      v-model="showModal"
      title="New bulk notification"
      :okText="$t('modal.confirm')"
      :cancelText="$t('modal.cancel')"
      @ok="bulkNotifyUsers"
      @cancel="resetForm"
    >
      <div>
        <va-input
          v-model="newNotification.title"
          label="Notification title"
          placeholder="Title goes here"
        />
        <va-input
          v-model="newNotification.message"
          label="Message"
          placeholder="Message"
          class="mb-4"
          type="textarea"
          :min-rows="3"
          :max-rows="5"
        />
      </div>
    </va-modal>
  </div>
</template>

<script>
import { notifyReaders } from '../../apollo/api/users';
import { NEW_NOTIFICATION } from '../../constants/defaultValues';
import { mapMutations } from 'vuex';

export default {
  name: 'new-notification-modal',
  props: {
    disabled: {
      type: Boolean,
      required: false,
    },
    selectedUsers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      newNotification: { ...NEW_NOTIFICATION },
      showModal: false,
    };
  },
  methods: {
    ...mapMutations(['setBackgroundLoading']),
    resetForm() {
      this.newNotification = { ...NEW_NOTIFICATION };
    },
    async bulkNotifyUsers() {
      if (this.selectedUsers.length === 0) {
        return;
      }

      this.setBackgroundLoading(true);
      try {
        console.log('Seinding ', this.newNotification);
        const notificationResponse = await notifyReaders(
          this.selectedUsers,
          this.newNotification.title,
          this.newNotification.message
        );

        if (notificationResponse.notifyReaders.response === 'OK') {
          this.showToast('Users notified successfuly', {
            position: 'top-right',
            duration: 800,
            fullWidth: false,
          });
        }
      } catch (e) {
        this.showToast(e, {
          position: 'top-right',
          duration: 1200,
          fullWidth: false,
        });
        console.log(e);
      }
      this.resetForm();
      this.$emit('clearSelectedUsers');
      this.setBackgroundLoading(false);
    },
  },
};
</script>
