import moment from 'moment';
export const NEW_AUTHOR = {
  name: '',
  twitter: '',
  instagram: '',
  patreon: '',
  website: '',
  picture: null,
};

export const NEW_NOTIFICATION = {
  title: '',
  message: '',
  data: {},
};

export const NEW_GENRE = {
  name: '',
  groupType: '',
  thumbnail: null,
};

export const GENRE_GROUPS_ARRAY = ['genre', 'theme', 'demographics', 'format'];

export const USER_SUBSCRIPTIONS_LEVELS = [0, 1, 2];

export const NEW_ADMIN_USER = {
  name: '',
  email: '',
  password: '',
  roles: [],
};

export const NEW_ROLE = {
  name: '',
  description: '',
  permissions: [],
};

export const MANGA_STATUSES = [
  { id: 1, text: 'On going' },
  { id: 2, text: 'Completed' },
  { id: 3, text: 'Dropped' },
];

export const DEFAULT_MANGA = {
  title: '',
  alternativeTitles: [],
  status: 1,
  authors: [],
  artists: [],
  cover: null,
  description: '',
  hentai: false,
  isAdult: false,
  type: null,
  genres: [],
  demographics: [],
  themes: [],
  tags: [],
  releaseDate: moment(new Date()).format('YYYY-MM-DD'),
  disabled: false,
};

export const BASE_NEW_SCRAPER = {
  scraperId: null,
  mangaId: '',
  siteMangaId: '',
  cronjobString: '',
  enabled: false,
};
